
import { Button } from "@mui/material";
import React, { useState } from "react";
import "./InfoMenu.css";

function InfoMenu({ InfoTopic, InfoText, InfoIMG, InfoGithub, InfoLiveSite }) {
  const [closed] = useState(false);
  window.scrollTo({
    top: 0,
    behavior: 'auto' // You can use 'auto' for instant scrolling
  });

  const handleReload = () => {
    window.location.reload(); // Reload the entire page
  };

  if (closed) {
    return null;
  }

  return (
    
    <>
      <div className="infoMenu">
        <h1 className="infoMenu__BigText">{InfoTopic}</h1>
        <p1 className="infoMenu__para">{InfoText}</p1>
        <div className="infoMenu__buttons">
        <button onClick={handleReload} className="close-button">Sluiten</button>
        {/* Conditional rendering of Github and Live Site buttons */}
      {InfoGithub && (
        <a href={InfoGithub} target="_blank" rel="noopener noreferrer">
          <Button color="primary" variant="contained" className="nav__button">
            <h3 className="nav__button--text">GitHub</h3>
          </Button>
        </a>
      )}

      {InfoLiveSite && (
        <a href={InfoLiveSite} target="_blank" rel="noopener noreferrer">
          <Button color="primary" variant="contained" className="nav__button">
            <h3 className="nav__button--text">Live Site</h3>
          </Button>
        </a>
      )}
      </div>
      </div>

      
    </>
  );
}

export default InfoMenu;
