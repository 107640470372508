import "./CardItem.css";
import { Button } from "@mui/material";



function CardItem({ openInfoMenu, cardImage, cardTitle, cardSubTitle, InfoTopic, InfoText, InfoIMG, InfoGithub, InfoLiveSite}) {
  function setMenu() {
    openInfoMenu({InfoTopic, InfoText, InfoIMG, InfoGithub, InfoLiveSite});
  }
  return (
    <div className="card">
      <img src={cardImage} alt="cardimg" className="carditem__img" />
      <h1 className="card__title">{cardTitle}</h1>
      <h3 className="card__sub-title">{cardSubTitle}</h3>
      <br></br>
      <Button
        onClick={setMenu}
        variant="outlined"
        className="card__button"
        color="primary"
      >
        <h3 className="card__button--text">Meer info!</h3>
      </Button>
    </div>
  );
}

export default CardItem;
