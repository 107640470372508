import "./NavBar.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <nav>
      <div className="navbar__container">
        <div className="nav__left nav__half">
          <Link to={"/"} className="nav__half">
            <h2 className="nav__title">TijmenT  </h2>
          </Link>
        </div>
        <div className="nav__right nav__half">
          <ul className="nav__list">
          <li className="nav__item">
              <Link to={"/"}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className="nav__button"
                >
                  <h3 className="nav__button--text">Home</h3>
                </Button>
              </Link>
            </li>
            <li className="nav__item nav__mobile--item">
              <Link to={"/projects"}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className="nav__button"
                >
                  <h3 className="nav__button--text">Projects</h3>
                </Button>
              </Link>
            </li>
            <li className="nav__item nav__mobile--item">
              <Link to={"/Skills"}>
                <Button
                  color="secondary"
                  variant="outlined"
                  className="nav__button"
                >
                  <h3 className="nav__button--text">Skills</h3>
                </Button>
              </Link>
            </li>
          
           
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
