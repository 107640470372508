import { useState } from "react";
import CardItem from "../components/CardItem";
import "./Projects.css";
import InfoMenu from "../components/InfoMenu";
import TerpstraIMG from "../assets/terpstra.png";
import StormIMG from "../assets/shopitem.png";
import FiveMIMG from "../assets/FiveM-Logo.png";
import DiscordIMG from "../assets/discord.webp";
function Projects() {
  const [item1, setItem1] = useState();
  const [item2, setItem2] = useState();
  const [item3, setItem3] = useState();
  const [item4, setItem4] = useState();
  const [item5, setItem5] = useState();
  const [infoMenu, setInfoMenu] = useState();

  function openInfoMenu({InfoTopic, InfoText, InfoIMG, InfoGithub, InfoLiveSite}) {
    setInfoMenu(
      <InfoMenu
      InfoTopic={InfoTopic}
      InfoText={InfoText}
      InfoIMG={InfoIMG}
      InfoGithub={InfoGithub}
      InfoLiveSite={InfoLiveSite}
      />
    );
  }

  
  setTimeout(() => {
    setItem1(
      <CardItem
      InfoTopic={"Storm Bypass"}
      InfoText={"In dit project ben ik mijn eigen onderneming gestart. Om het kort te houden: dit is een Python applicatie waarmee je FiveM kunt blijven spelen nadat je verbannen bent. Dit project heeft mij niet alleen geholpen met het leren van Python, maar ook met klanten omgaan en online verkoop."}
      InfoIMG={"123"}
      cardTitle={"Storm Bypass"}
      InfoGithub={"https://github.com/TTSoftwareServices/Storm-Bypass"}
      InfoLiveSite={"https://stormservices.sellpass.io/"}
        cardImage={StormIMG}
        cardSubTitle={"FiveM Ban Bypass Tool"}
        openInfoMenu={openInfoMenu}
      />
    );
    setItem2(
      <CardItem
      InfoTopic={"Storm Generator"}
      InfoText={"Dit was puur een project voor eigen gemak. Ik wilde een Discord bot die gemakkelijk verse Rockstar-accounts voor me kon genereren, die ik nodig had voor mijn Storm Bypass project."}
      InfoIMG={"123"}
      cardTitle={"Storm Generator"}
      cardImage={DiscordIMG}
        cardLink={"https://github.com/TTSoftwareServices/Storm-Generator"}
        cardSubTitle={"NodeJS Discord bot"}
        openInfoMenu={openInfoMenu}
      />
      
    );
    setItem3(
      <CardItem
      InfoTopic={"Terpstra Tuinen"}
      InfoText={"In 2022 heb ik Terpstra Tuinen geholpen bij het ontwikkelen van een webapplicatie met Laravel. In deze webapplicatie kunnen werknemers gemakkelijk hun uren bijhouden. De GitHub repository van dit project is privé vanwege veiligheidsredenen en niet bedoeld voor het publiek. Ik zou het gedeeltelijk kunnen laten zien op verzoek."}
      InfoIMG={"123"}
        cardTitle={"Terpstra Tuinen"}
        cardImage={TerpstraIMG}
        cardSubTitle={"Hoveniers bedrijf Medemblik"}
        openInfoMenu={openInfoMenu}
      />
    );
    setItem4(
      <CardItem
      InfoTopic={"VT_Leaderboard"}
      InfoText={"Dit is een project samen met een vriend, wij hebben hier een script gemaakt die jouw Kill / Death Ratio bijhoud in de Game FiveM. (Lua gedeelte is door mij zie github)"}
      InfoIMG={"123"}
      InfoGithub={"https://github.com/JariKee/Leaderboard"}
      cardTitle={"VT_Leaderboard"}
      cardImage={FiveMIMG}
        cardLink={"replace with link for 15"}
        cardSubTitle={"FiveM K/D Leaderboard"}
        openInfoMenu={openInfoMenu}
      />
      
    );
    setItem5(
      <CardItem
      InfoTopic={"TT_HitIndicator"}
      InfoText={"Dit was mijn eerste grote Lua project wederom voor FiveM, dit is een script waarmee je een hitmelding krijgt met hoeveel schade jij hebt gedaan bij jouw tegenstander."}
      InfoIMG={"123"}
      InfoGithub={"https://github.com/TijmenT/TT_Hitindicator"}
      cardTitle={"TT_HitIndicator"}
      cardImage={FiveMIMG}
        cardLink={"replace with link for 15"}
        cardSubTitle={"FiveM Hitmarker Script"}
        openInfoMenu={openInfoMenu}
      />
      
    );
  }, 1500);

  return (
    <div className="products">
      <div className="prow">
        <div className="products__header headerStyle">
          <ul className="products__list">
            <li className="product__item">{item1}</li>
            <li className="product__item">{item2}</li>
            <li className="product__item">{item3}</li>
            <li className="product__item">{item4}</li>
            <li className="product__item">{item5}</li>
          </ul>
        </div>
      </div>
      {infoMenu}
    </div>
  );
}

export default Projects;
