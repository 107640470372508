import React from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Home from "./pages/Home";
import NotFoundPage from "./pages/NotFoundPage";
import Projects from "./pages/Projects";
import Skills from "./pages/Skills";

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#395b80",
      },
      secondary: {
        main: "#7dbad2",
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Router>
          <NavBar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/skills" element={<Skills />} />
            <Route path="*" element={<NotFoundPage />} /> {/* Use "*" for the not found route */}
          </Routes>
          <Footer />
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
