import { useState } from "react";
import CardItem from "../components/CardItem";
import "./Skills.css";
import PythonIMG from "../assets/Python.png";
import PhpIMG from "../assets/php.png";
import LuaIMG from "../assets/Lua.png";
import InfoMenu from "../components/InfoMenu";
import JSIMG from "../assets/pngegg.png";
import CPPImg from "../assets/cpp.png"
function Skills() {
  const [item1, setItem1] = useState();
  const [item2, setItem2] = useState();
  const [item3, setItem3] = useState();
  const [item4, setItem4] = useState();
  const [item5, setItem5] = useState();
  const [infoMenu, setInfoMenu] = useState();

  function openInfoMenu({InfoTopic, InfoText, InfoIMG, InfoGithub, InfoLiveSite}) {
    setInfoMenu(
      <InfoMenu
      InfoTopic={InfoTopic}
      InfoText={InfoText}
      InfoIMG={InfoIMG}
      InfoGithub={InfoGithub}
      InfoLiveSite={InfoLiveSite}
      />
    );
  }
  setTimeout(() => {
    setItem1(
      <CardItem
        cardTitle={"     Python     "}
        InfoTopic={"Python"}
        InfoText={"Python is de taal waarmee ik gestart ben, hiermee heb ik ook mijn grootste projecten gehad. Ik vind Python een zeer fijne taal en wil hier zeker verder op bouwen."}
        cardImage={PythonIMG}
        openInfoMenu={openInfoMenu}
        cardSubTitle={"3 years of experience"}
      />
    );
    setItem2(
      <CardItem
      cardTitle={"      Php      "}
        cardLink={"Storm"}
        InfoTopic={"Php"}
        InfoText={"Ik heb de meeste kennis en controle over PHP naar mijn mening. Hier heb ik meerdere Content Management Systems gemaakt. Daarnaast heb ik ook ervaring met Composer en Laravel."}
        cardImage={PhpIMG}
        cardSubTitle={"3 years of experience"}
        openInfoMenu={openInfoMenu}
      />
    );
    setItem3(
      <CardItem
      cardTitle={"      Lua      "}
      cardImage={LuaIMG}
      InfoTopic={"Lua"}
      InfoText={"Dit was een moeilijke taal om mee te beginnen, de syntax van deze taal is niet te vergelijken met bijvoorbeeld Javascript. Ik heb hier uiteindelijk wel leuke projecten mee gedraaid voor Garry's Mod en FiveM."}
        cardLink={"replace with link for 15"}
        cardSubTitle={"2 years of experience"}
        openInfoMenu={openInfoMenu}
      />
      
    );
    setItem4(
      <CardItem
      cardTitle={"    Javascript    "}
      cardImage={JSIMG}
      InfoTopic={"Sinds 2022 ben ik veel bezig met Javascript, ik beheers ook NodeJS en daarop ReactJS (Waar deze portfolio mee gemaakt is)."}
        cardLink={"replace with link for 15"}
        cardSubTitle={"2 years of experience"}
        openInfoMenu={openInfoMenu}
      />
      
    );
    setItem5(
      <CardItem
      cardTitle={"C++"}
      cardImage={CPPImg}
      InfoTopic={"      C++      "}
      InfoText={"Ik ben sinds kort begonnen met C++, ik heb al meedere van mijn oude simpele python projecten proberen te herschrijven in C++. Ik ben momenteel bezig met de WDK, er is weinig specifieke documentatie voor deze taal waardoor ik vaak vast loop."}
        cardLink={"replace with link for 15"}
        cardSubTitle={"3 months of experience"}
        openInfoMenu={openInfoMenu}
      />
      
    );
  }, 1500);

  return (
    <div className="products">
      <div className="prow">
        <div className="products__header headerStyle">
          <ul className="products__list">
            <li className="product__item">{item1}</li>
            <li className="product__item">{item2}</li>
            <li className="product__item">{item3}</li>
            <li className="product__item">{item4}</li>
            <li className="product__item">{item5}</li>
          </ul>
        </div>
      </div>
      {infoMenu}
    </div>
  );
}

export default Skills;
