
import "../pages/Home.css";
function Text() {
  return (
    <>
      <div className="title__animation">
        <h1 className="home__title">
              My name is <span class="Text__BigFont">Tijmen Terpstra</span>,
              I'm a <span class="Text__BigFont">backend</span> developer.
      </h1>
      </div>
      <p className="home__para">
      Ik ben 19 jaar oud en kom uit het dorpje Opperdoes. Sinds mijn jeugd ben ik al gefascineerd door computers en software, met name door de technische aspecten van websites.
      </p>
      
    </>
  );
}

export default Text;
